@use "sass:map";
@import "variables";
@import "configs/context-menu";
@import "configs/icon-button";
@import "table-toolbar-mixin";
@import "devextreme/scss/widgets/material/colors.scss";


@mixin icon($color: null, $size: $global-icon-size) {
  $icon-size: $size;

  i, .dx-icon {
    @if $color {
      color: $color !important;
      fill: $color !important;
    }

    text-align: center;
    font-size: $icon-size;
    line-height: $icon-size + 1px;
  }

  .dx-icon, img, svg {
    height: $icon-size;
    width: $icon-size;
  }
}

@mixin icon_button($size, $color: null) {
  $config: get_icon_button_config($size);
  $widget-size: map_get($config, 'widget-size');
  $icon-size: map_get($config, 'icon-size');
  $icon-wrapper-padding: map_get($config, 'icon-wrapper-padding');

  height: $widget-size !important;
  min-width: $widget-size;

  .dx-button-content {
    padding: $icon-wrapper-padding;
  }

  @include icon($color, $icon-size);
}

@mixin button-settings() {
  @include toolbar-icon();
  min-width: 16px;
  margin-right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin toolbar-hidden-menu-text($config) {
  font-size: map.get($config, 'font-size');
  line-height: map.get($config, 'line-height');
  font-weight: 400;
  color: $dark;
  text-transform: none;
}

@mixin context-menu($size) {
  $config: cm_config();
  .dx-context-menu {
    border-radius: 5px !important;

    .dx-submenu {
      border-radius: 5px !important;

      .dx-menu-items-container {
        padding: 0 !important;
      }

      .dx-item-content.dx-menu-item-content {
        display: flex;
        align-items: center;

        &:has(>[data-press='true']) {
          @include pressed-d5-button();
        }

        & .dx-icon {
          height: $toolbar-icon-size;
          line-height: $toolbar-icon-size;
          width: $toolbar-icon-size;
          margin-right: 8px;
        }
      }
    }

    .dx-menu-item-wrapper {
      border-bottom: 1px solid rgba(102, 102, 102, 0.08);

      .dx-menu-item {
        height: map.get($config, 'height');
        min-width: 230px;
        border-radius: 3px;

        @media (max-width: 480px) {
          min-width: 130px;
          max-width: 200px;
          height: auto;

          .dx-menu-item-text {
            white-space: normal;
          }
        }
      }

      &:last-child {
        border-bottom: none;
      }

      .new-group-line {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #D4D7E4;
      }

      .menu-item-container {
        display: flex;
        flex: 1 0;
        align-items: center;
        position: relative;

        span {
          padding-left: 25px;
          @include toolbar-hidden-menu-text($config);
        }

        i {
          position: absolute;
          @include toolbar-icon();
        }
      }

      .dx-menu-item {
        span {
          @include toolbar-hidden-menu-text($config);
          line-height: 14px;
        }

        i {
          @include toolbar-icon();
        }
      }

      .dx-menu-item.dx-state-disabled {
        opacity: 1 !important;
        color: $gray;

        span {
          color: $gray;
        }
      }
    }
  }

  .help-context {
    .dx-menu-item-wrapper {
      &:last-child {
        .dx-menu-item-text {
          font-weight: 800;
        }
      }
    }
  }
}

@mixin field-border() {
  border: 2px solid $gray;
  border-radius: 5px !important;

  &.underline {
    border: none;
    border-bottom: 2px solid $gray;
    border-radius: 0 !important;
  }

  &.none {
    border: none;
  }

  &:not(.dx-state-readonly):hover {
    border-color: $gray-2 !important;
  }
  &.dx-state-readonly {
    @include field-dotted-border();
  }

}

@mixin field-border-invalid() {
  box-shadow: inset 0 0 0 1px rgb(247 104 91 / 40%) !important;
  border: 1px solid rgb(247 104 91 / 40%);

  &.underline {
    border: none;
    border-radius: 0 !important;
    box-shadow: none !important;
    border-bottom: 2px solid rgb(247 104 91 / 40%);
  }

  &.none {
    border: none;
    box-shadow: none !important;
  }
}

@mixin field-dotted-border() {
  border: 2px dotted $light-gray-readOnly;
  border-radius: 5px !important;
  &.underline {
    border: none;
    border-bottom: 2px dotted $light-gray-readOnly;
    border-radius: 0 !important;
  }

  &:not(.dx-state-focused) {
    &.none {
      border: 2px dotted transparent;
    }
  }

  &:hover {
    border-color: $gray-7;
  }
  &.blank-field {
    border-style: solid;
    // Виключення для readOnly полів з border-style = underline
    // Не використовуємо стандартний стиль dotted
    &.underline {
      border: none;
      border-bottom: 2px solid $gray;
    }
  }
}

@mixin strong-field-border-invalid() {
  box-shadow: inset 0 0 0 1px rgb(247 104 91 / 40%) !important;
  border: 2px solid rgb(247 104 91 / 40%);
}

$group-font-size: 14px;
$group-font-color: #000;
$group-font-color-active: $primary;
$group-caption-padding: 8px;
$group-caption-border: 2px;

@mixin group-caption-wrapper-no-border {
  padding-bottom: $group-caption-padding;
  margin-bottom: $group-caption-padding;
  position: relative;

  &:after {
    content: '';
    height: $group-caption-border;
    width: 100%;
    background: $gray;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

@mixin group-caption-wrapper-custom($position) {
  @include group-caption-wrapper-no-border;
  padding-bottom: 0;
  margin-bottom: 0;


  &:after {
    content: '';
    width: 3px;
    height: 100%;
    background: $gray;
    position: absolute;

    @if $position == 'left' {
      display: none;
    } @else if $position == 'right' {
      display: none;
    } @else if $position == 'bottom' {
      width: 100%;
      height: $group-caption-border;
      top: 0;
      left: 0;
    } @else {
      width: 100%;
      height: $group-caption-border;
      bottom: 0;
      left: 0;
    }
  }

  .dx-tab {
    &.dx-tab-selected {
      &:after {
        z-index: 1;
        @if $position == 'left' {
          height: 100%;
          width: 3px;
          right: -2px;
          top: 0;
        } @else if $position == 'right' {
          height: 100%;
          width: 3px;
          left: 0;
          top: 0;
        } @else if $position == 'bottom' {
          width: 100%;
          height: 3px;
          top: 0;
          left: 0;
        } @else {
          width: 100%;
          height: 3px;
          bottom: -1px;
          left: 0;
        }
      }

    }
  }

}

@mixin group-caption {
  font-size: $group-font-size;
  text-transform: none;
  line-height: 20px;
  font-weight: 500;
  font-style: normal;
  color: $group-font-color;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin group-caption-active {
  @include group-caption;
  color: $group-font-color-active;
}

@mixin group-caption-tab {
  padding-right: 7px;
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  max-width: 100%;
  -webkit-user-drag: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin panel-border() {
  &.outlined {
    border: 2px solid $borderPanelColor;
    border-radius: 10px;
    margin: 6px !important;
    padding: 5px !important;
    box-shadow: 0 2px 5px 0 $borderPanelColor;
    height: auto !important;
  }
}

@mixin panel-border-caption($caption-text-class) {
  position: absolute;
  top: 0;
  left: 16px;
  width: calc(100% - 16px);
  transform: translateY(-5%);

  #{$caption-text-class} {
    line-height: 16px;
    background: var(--group-caption-bg, #fff);
    z-index: 1;
    padding-left: 2px;
    padding-right: 2px;
  }
}

@mixin list-items-common-styles {
  .dx-item.dx-list-item {
    & .dx-list-item-content {
      & .dx-icon {
        color: #1A1A1A;
      }
    }

    & .dx-list-reorder-handle-container {
      & .dx-list-reorder-handle {
        color: #1A1A1A;
      }

      color: #1A1A1A;
    }

    &:hover:not(.dx-list-item-selected) {
      & .dx-list-item-content {
        & .dx-icon {
          color: $secondary;
        }
      }

      & .dx-list-reorder-handle-container {
        & .dx-list-reorder-handle {
          color: $secondary;
        }

        color: $secondary;
      }
    }

    &-selected {
      & .dx-list-item-content .dx-icon {
        color: rgba(255, 255, 255, 0.8);
      }

      & .dx-list-item-content,
      & .dx-list-reorder-handle-container {
        & .dx-list-reorder-handle {
          color: rgba(255, 255, 255, 0.8);
          background-color: $secondary;
        }

        color: rgba(255, 255, 255, 0.8);
        background-color: $secondary;
      }
    }
  }
}

@mixin tagboxStylingModes($applyToContainer) {
  //якщо $applyToContainer, то схеми проставляються на контейнер тагбокса і всі таги розмальовуються
  @if $applyToContainer {
    @include color-tag();
  } @else {
    //це блок для тагів в таблиці (не редагування)
    //якщо не $applyToContainer, то схеми проставляються в дів .multiselect-container

    &.color-scheme-success.styling-mode-contained {
      @include color-scheme-success-contained;
    }
    &.color-scheme-success.styling-mode-text {
      @include color-scheme-success-text;
    }
    &.color-scheme-success.styling-mode-outlined {
      @include color-scheme-success-outlined;
    }

    // success

    &.color-scheme-default.styling-mode-text {
      @include color-scheme-default-text;
    }
    &.color-scheme-default.styling-mode-outlined {
      @include color-scheme-default-outlined;
    }
    &.color-scheme-default.styling-mode-contained {
      @include color-scheme-default-contained
    }
    // default

    &.color-scheme-warning.styling-mode-text {
      @include color-scheme-warning-text;
    }
    &.color-scheme-warning.styling-mode-outlined {
      @include color-scheme-warning-outlined;
    }
    &.color-scheme-warning.styling-mode-contained {
      @include color-scheme-warning-contained;
    }
    // warning

    &.color-scheme-danger.styling-mode-text {
      @include color-scheme-danger-text;
    }
    &.color-scheme-danger.styling-mode-outlined {
      @include color-scheme-danger-outlined;
    }
    &.color-scheme-danger.styling-mode-contained {
      @include color-scheme-danger-contained;
    }
    // danger

    // normal
    &.color-scheme-normal.styling-mode-text {
      @include color-scheme-normal-text;
    }
    &.color-scheme-normal.styling-mode-outlined {
      @include color-scheme-normal-outlined;
    }
    &.color-scheme-normal.styling-mode-contained {
      @include color-scheme-normal-contained;
    }
  }


  .dx-tag > div, .dx-tag {
    @include color-tag();
  }
}

// в тагбокс і CustomTagBox
@mixin color-tag() {
  &.color-scheme-success.styling-mode-contained {
    .dx-tag-content {
      @include color-scheme-success-contained;
    }
  }

  &.color-scheme-success.styling-mode-text {
    .dx-tag-content {
      @include color-scheme-success-text;
    }
  }

  &.color-scheme-success.styling-mode-outlined {
    .dx-tag-content {
      @include color-scheme-success-outlined;
    }
  }

  &.color-scheme-default.styling-mode-text {
    .dx-tag-content {
      @include color-scheme-success-outlined;
    }
  }

  &.color-scheme-default.styling-mode-outlined {
    .dx-tag-content {
      @include color-scheme-default-outlined;
    }
  }

  &.color-scheme-default.styling-mode-contained {
    .dx-tag-content {
      @include color-scheme-default-contained;
    }
  }

  &.color-scheme-warning.styling-mode-text {
    .dx-tag-content {
      @include color-scheme-warning-text;
    }
  }

  &.color-scheme-warning.styling-mode-outlined {
    .dx-tag-content {
      @include color-scheme-warning-outlined;
    }
  }

  &.color-scheme-warning.styling-mode-contained {
    .dx-tag-content {
      @include color-scheme-warning-contained;
    }
  }

  &.color-scheme-danger.styling-mode-text {
    .dx-tag-content {
      @include color-scheme-danger-text;
    }
  }

  &.color-scheme-danger.styling-mode-outlined {
    .dx-tag-content {
      @include color-scheme-danger-outlined;
    }
  }

  &.color-scheme-danger.styling-mode-contained {
    .dx-tag-content {
      @include color-scheme-danger-contained;
    }
  }

  &.color-scheme-normal.styling-mode-text {
    .dx-tag-content {
      @include color-scheme-normal-text;
    }
  }

  &.color-scheme-normal.styling-mode-text {
    .dx-tag-content {
      @include color-scheme-normal-text;
    }
  }

  &.color-scheme-normal.styling-mode-outlined {
    .dx-tag-content {
      @include color-scheme-normal-outlined;
    }
  }

  &.color-scheme-normal.styling-mode-contained {
    .dx-tag-content {
      @include color-scheme-normal-contained;
    }
  }
}

@mixin styling-mode-contained($bg, $color) {
  background-color: $bg;
  color: $color;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  @include delete-icon-color-scheme($color)
}

@mixin styling-mode-outlined($bg, $color, $border-color) {
  color: $color;
  background: $bg;
  border: 1px solid $border-color;
  @include delete-icon-color-scheme($color)
}

@mixin styling-mode-text($bg, $color) {
  background-color: transparent;
  border: 1px solid transparent;
  color: $color;
  @include delete-icon-color-scheme($color)
}

@mixin color-scheme-normal-contained {
  @include styling-mode-contained($white, $black);
}

@mixin color-scheme-normal-outlined {
  @include styling-mode-outlined($white, $black, $dark-30);
}

@mixin color-scheme-normal-text {
  @include styling-mode-text($white, $black);
}


@mixin color-scheme-danger-contained {
  @include styling-mode-contained($base-danger, $white);
}

@mixin color-scheme-danger-outlined {
  @include styling-mode-outlined($white, $base-danger, $base-danger);
}

@mixin color-scheme-danger-text {
  @include styling-mode-text($white, $base-danger);
}


@mixin color-scheme-warning-contained {
  @include styling-mode-contained($warn, $white);
}

@mixin color-scheme-warning-outlined {
  @include styling-mode-outlined($white, $warn, $warn);
}

@mixin color-scheme-warning-text {
  @include styling-mode-text($white, $warn);
}

@mixin color-scheme-default-contained {
  @include styling-mode-contained($secondary, $white);
}

@mixin color-scheme-default-outlined {
  @include styling-mode-outlined($white, $secondary, $secondary);
}

@mixin color-scheme-default-text {
  @include styling-mode-text($white, $secondary);
}

@mixin color-scheme-success-contained {
  @include styling-mode-contained($base-success, $white);
}

@mixin color-scheme-success-outlined {
  @include styling-mode-outlined($white, $base-success, $base-success);
}

@mixin color-scheme-success-text {
  @include styling-mode-text($white, $base-success);
}

@mixin delete-icon-color-scheme($color) {
  .dx-tag-remove-button {
    &:before {
      color: $color;
    }
  }
}
@mixin vertical-tab-separates($separate-color: $gray-7-alpha) {
  .dx-tab:not(.dx-tab-selected) {
    &:after {
      content: '';
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      border-top: 1px solid $separate-color;
    }
  }
  .dx-tab.dx-tab-selected {
    &:before {
      content: '';
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      border-top: 1px solid $separate-color;
    }
  }
}

@mixin disabled-nav-tab-button {
  .dx-button.dx-state-disabled.dx-tabs-nav-button {
    .dx-button-content {
      opacity: 1 !important;
      .dx-icon {
        color: $gray-7;
      }
    }
  }
}


@function get_title_config($size) {
  $small-config: (
          'form-title': 13px,
          'form-title-padding': 6px 15px 5.5px 15px,
          'group-font-size': 11px,
          'table-toolbar-height': 21px,
  );
  $medium-config: (
          'form-title': 16px,
          'form-title-padding': 3px 15px 3.5px 15px,
          'group-font-size': 14px,
          'table-toolbar-height': 24px,
  );
  $large-config: (
          'form-title': 20px,
          'form-title-padding': 1px 15px 0 15px,
          'group-font-size': 17px,
          'table-toolbar-height': 27px,
  );

  $config: $medium-config;

  @if $size == 'small' {
    $config: $small-config;
  } @else if $size == 'large' {
    $config: $large-config;
  }
  @return $config;
}

@function get_icon_config() {
  $config: (
    'tab-icon-size': 18px,
  );
  @return $config;
}
