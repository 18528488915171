@use 'sass:map';
@import "header-cells";
@import "data-cells";
@import "../../../scss/ui3/configs/table";
@import "inline-editing";

.table-wrapper, .dx-sortable-clone.dx-sortable-dragging {

  @each $size in $row-sizes {
    &.row-size-#{$size} {
      @include inline-editors-size(row_config($size));
      @include header-cell-size(header_config($size));
      @include data-row-size(row_config($size));
      @include tree-list-arrow-columns;
    }

    &.row-size-small {
      .dx-switch {
        height: 10px;
      }
    }
  }

  .dx-state-disabled {
    box-shadow: none !important;

    input {
      box-shadow: none !important;
    }

    .dx-placeholder,
    .dx-texteditor-buttons-container {
      display: none;
    }
  }

  // класс-модификатор чтобы убрать все бордеры
  &--hidden-borders {
    .dx-treelist-rowsview,
    .dx-datagrid-rowsview {
      border-top: none;
    }
  }

  .dx-treelist-focus-overlay {
    &:after {
      background-color: unset;
    }
  }

  .dx-placeholder {
    padding-left: 6px;
  }

  // Стиль ячейки при butch редактировании
  .dx-editor-cell.dx-focused:not(.dx-datagrid-invalid) {
    background-color: unset !important;
    border-top: unset !important;
    border-bottom: unset !important;

    .dx-texteditor:not(.dx-state-disabled) {
      .dx-texteditor-input {
        border-radius: 3px;
        border: 1px solid $primary-60;
        box-shadow: 0 2px 10px rgba(24, 89, 255, 0.5);
      }
    }
  }
  //Стилі для колонки-клону при drag&drop
  .dx-treelist .dx-command-drag {
    padding-bottom: 7px !important;
    padding-top: 7px !important;
    padding-left: 3px !important;
  }
  .dx-treelist .dx-command-drag .dx-treelist-drag-icon:before{
    content: '\2807';
  }
}

.custom-cm-container {
  display: flex;
  align-items: center;

  &.section-item {
    padding-left: 15px;
  }


  .custom-cm-icon {
    margin-right: 11px !important;
  }

  .custom-cm-text {
    vertical-align: baseline !important;
  }

  .custom-cm-hotkey {
    font-weight: bold !important;
    position: absolute;
    right: 15px;
  }
}
