.d5-layout-group {
  display: flex;
  flex: 1 1 auto;

  .filter-field-container {
    display: -webkit-flex;
    -webkit-align-items: center;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;

    .field-container-between {
      display: flex;
      width: 100%;
      flex-direction: column;

      .field-container {
        -webkit-flex: 1 1 auto; /* Safari 6.1+ */
        -ms-flex: 1 1 auto; /* IE 10 */
        flex: 1 1 auto;


        &.to {
          margin-left: 3px;
        }
      }
    }

    .d5-field-container {
      flex-grow: 1;
    }

    .dx-field-item-label-location-top {
      & + .dx-field-item-content {
        .filter-field-container {
          padding-left: 0 !important;
        }
      }
    }

    & .dx-button-content {
      & .dx-icon.far.fa-list {
        vertical-align: baseline;
      }
    }
  }


  // здесь важен порядок стилей, при изменении расположения фильтров (row / col) возможно нужны будут коррективы в данных стилях
  &.flex-column {
    .without-widget {
      .operation-type-widget {
        display: block;
        opacity: 0;
      }
    }
  }

  &.flex-row {
    .without-widget {
      .operation-type-widget {
        display: none;
      }
    }
  }
}
