@import "variables";

.accent-bg {
  background-color: $primary;
}

.secondary-bg {
  background-color: $secondary;
}

.primary-text-color {
  color: $dark;
}

.label-text-color,
.button-icon-color {
  color: $gray-2 !important;
}

.button-accent-icon-color {
  color: $primary !important;
}
