@import "./variables";
@import "src/scss/ui3/tables/utils";
@import "src/scss/ui3/tables/variables";
@import "src/scss/ui3/mixins";
@import "configs/base";
@import "src/scss/prepend-slash";

@mixin invalid-icon-sizes($size) {
  .form-mode-#{$size} {
    .d5-field {
      &.dx-invalid.dx-show-invalid-badge {
        .dx-texteditor-buttons-container:before {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

@mixin boolean-form($size) {
  $config: base_config($size);

  .boolean-editor-wrapper {
    outline: none;

    &:focus {
      .button-group-container {
        outline: 2px solid $gray-2;
        border-radius: 5px;
      }

      .dx-state-readonly {
        .dx-checkbox-icon {
          border-color: $gray-2 !important;
        }
      }

      .readonly-state {
        outline: 2px dotted $gray-2;
      }
    }

    .dx-buttongroup {
      .dx-button {
        width: 100%;
      }
    }
  }

  .editor-settings-#{$size}:not(.table-boolean-wrapper) {
    display: flex;
    align-items: center;
    justify-content: center;

    .button-group-container {
      height: 30px;

      .dx-buttongroup {
        height: 100% !important;

        .dx-button {
          height: auto !important;
        }
      }
    }

    .buttons-group {
      display: flex;

      .d5-field.buttons-group .dx-buttongroup-item.dx-item.dx-button.dx-item-selected.dx-state-disabled {
        opacity: 0.6;
      }

      .dx-state-disabled .dx-widget, .dx-state-disabled.dx-widget,
      .dx-buttongroup-item.dx-button.dx-button-mode-contained.dx-state-disabled .dx-button-content {
        opacity: 1;
      }

      .dx-buttongroup .dx-widget .dx-state-disabled {
        background-color: $light-gray-readOnly;
      }

      &.invalid {
        .d5-field {
          @include strong-field-border-invalid;
        }
      }

      &.dx-buttongroup-wrapper {
        display: flex;
        height: auto;

        .dx-buttongroup-item.dx-item.dx-button.dx-button-normal {
          box-shadow: 0 1px 3px rgb(0 0 0 / 24%);
          flex: 1 1 0;

          .dx-button-content {
            padding: 0;
            height: map_get($config, 'field-height');
          }
        }
      }
    }

    .dx-button-text {
      font-size: map_get($config, "font-size");
    }
  }
}

@each $size in $row-sizes {
  @include boolean-form($size);
  @include invalid-icon-sizes($size);
}

.icon-field {
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.d5-multi-select-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.date-range {
  width: 100%;
  display: flex;

  .dx-texteditor-input-container {
    cursor: pointer;
  }

  input {
    pointer-events: none;
  }

  &__row {
    flex-direction: row;
  }

  &__column {
    flex-direction: column;
  }
}

.d5-field {
  min-width: 120px;
  @include field-border();

  &.label-widget,
  &.switch-control,
  &.radio-group,
  &.image-control,
  &.check-box,
  &.decoration-item-text {
    border: none !important;
  }

  &.image-control {
    height: 100% !important;
    flex-shrink: 1;
    flex-grow: 0;

    & > img {
      object-fit: contain;
      display: block;
      height: auto;
      margin: auto;
    }
  }

  &.check-box {
    &.dx-state-readonly {
      .dx-checkbox-icon {
        color: $gray-2;
        border: 2px dotted #D4D7E4;
        border-radius: 2px !important;
        background: transparent;
      }
    }

    &.dx-state-disabled {
      opacity: 1;

      .dx-checkbox-icon {
        background: $gray-7;
      }
    }

    & .dx-checkbox-container {
      display: flex;
      align-items: center;
    }
  }

  &.static-field {
    @include field-border();
  }


  //падингом управляется высота полей вроде бы всех кроме tagbox
  &.dx-editor-filled .dx-placeholder::before,
  &.dx-editor-filled .dx-texteditor-input,
  &.dx-editor-outlined .dx-placeholder::before,
  &.dx-editor-outlined .dx-texteditor-input {
    padding: 0 8px;
  }

  .dx-invalid-message > .dx-overlay-content {
    display: none;
  }

  &.dx-editor-outlined, &.dx-htmleditor-outlined {
    box-shadow: none !important;
    border-color: $gray;

    &.dx-state-disabled, &.dx-state-readonly, &.dx-state-hover {
      box-shadow: none !important;
    }

    &.dx-state-focused {
      border-color: $gray-2 !important;
    }

    &.dx-invalid {
      @include field-border-invalid();
    }

    &.dx-valid .dx-texteditor-input-container::after {
      display: none;
    }
  }

  &.dx-texteditor.dx-editor-outlined.dx-state-readonly .dx-texteditor-input {
    color: $dark;
    overflow: hidden;
    overflow-y: auto;
  }

  &.dx-state-readonly:not(.switch-control, .check-box, .blank-field) {
    background-color: transparent;

    .dx-texteditor-buttons-container {
      .dx-button:not(.operation-type-widget) {
        &:not(.dx-buttongroup-item) {
          background-color: transparent;
        }
      }
    }

    .dx-texteditor-buttons-container:before {
      right: 10px;
    }
  }

  &.dx-state-disabled:not(.switch-control, .check-box, .blank-field) {
    background-color: $gray-readOnly;

    .dx-label span {
      background-color: $gray-readOnly;
    }

    &.d5-field.multi-select-control .dx-tag .dx-tag-content,
    &.d5-field.custom-tagbox .dx-tag .dx-tag-content {
      background-color: $dark-gray-readOnly;
      color: white;
      border: none;
      padding-right: 16px;

      .dx-tag-remove-button {
        display: none;
      }
    }

    .dx-texteditor-buttons-container {
      .dx-button:not(.operation-type-widget) {
        &:not(.dx-buttongroup-item) {
          background-color: transparent;
        }
      }
    }

    .dx-texteditor-buttons-container:before {
      right: 10px;
    }

    &.dx-state-hover {
      border: 2px solid $gray-readOnly;
    }
  }

  &.dx-textarea {
    .dx-icon-clear {
      margin-top: 7px;
    }
  }

  &.dx-editor-outlined .dx-texteditor-buttons-container > .dx-button {
    margin-left: 0;
    margin-right: 0;

    &.dx-state-disabled {
      background: transparent;
    }

    &:not(.d5-editor-button) {
      .dx-icon {
        color: $gray-2;
        fill: $gray-2;
      }
    }
  }


  //замена всех дх дропдавн иконок на фонт овсом
  .dx-dropdowneditor-button, .dx-dropdowneditor, &.dx-dropdowneditor {
    .dx-dropdowneditor-icon {
      @include fa-icon-solid();
      font-size: 15px;
      color: $gray;

      &:before {
        content: prepend-slash("f078");
      }
    }

    &.dx-dropdowneditor-active {
      .dx-dropdowneditor-icon:before {
        color: $gray-2;
      }
    }
  }

  // для tagbox отдельно выставляем высоту
  &.multi-select-control,
  &.custom-tagbox {
    //отцентровка тагов
    .dx-tag {
      display: inline-block;
      max-width: 88px;
      min-width: 50px;

      & + .dx-texteditor-input {
        margin: 0 !important;
        padding: 0 !important;
      }

      .dx-tag-content {
        display: flex;
        align-items: center;
        height: 100%;
        color: #FFFFFF;
        background-color: $secondary;
        border-radius: 2px;
        font-weight: 400;
        padding-right: 27px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        & > span {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .tag-text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .dx-tag-remove-button {
        &:before {
          background-color: transparent;
          color: #FFFFFF;
        }
      }
    }

    &.dx-editor-filled, &.dx-editor-outlined {
      //отцентровка ввода в tagbox (поиск)
      .dx-texteditor-input {
        padding: 0;
        height: 100%;
        margin-top: 0;
      }

      .dx-texteditor-buttons-container:last-child > .dx-button:last-child,
      .dx-texteditor-buttons-container:last-child > .dx-button:last-child {
        margin-right: 3px;
      }

      &:not(.d5-editor-button).dx-button {
        max-height: 30px;
        max-width: 40px;
      }

      //установка высоты в tagbox
      .dx-tag-container {
        margin: 0;
        overflow: hidden;
        gap: 0;
      }
    }
  }

  &.multi-select-control,
  &.custom-tagbox {
    @include tagboxStylingModes(true);
  }

  &.multi-select-control,
  &.custom-tagbox {
    &.full-width {
      .dx-tag {
        // -3 пикселя балансирует ширину тег бокса что бы они все были на одном уровне
        max-width: calc(100% - 3px);
        width: 100%;
      }
    }
    &.full-on-border-title-mode {
      .dx-tag-container {
        padding-top: 8px;
      }
    }
  }

  &.dx-state-readonly {
    &.blank-field {
      background-color: unset;
      height: 100%;

      .dx-texteditor-input-container .dx-texteditor-input {
        color: #000000;
      }

      .dx-texteditor-container {
        align-items: baseline;
      }
    }
  }

  &.buttons-group {
    .dx-buttongroup-item.dx-item.dx-button {
      &.dx-item-selected {
        color: $white;
        background-color: $secondary;

        .dx-button-content {
          .dx-button-text {
            color: $white;
          }
        }
      }

      .dx-button-content {
        .dx-button-text {
          text-transform: none;
        }
      }
    }
  }

  //

  &.dx-invalid.dx-show-invalid-badge {
    .dx-texteditor-input-container:after {
      display: none;
    }

    .dx-texteditor-buttons-container {
      position: relative;
    }

    .dx-texteditor-buttons-container:not(:has(.dx-button,.dx-dropdowneditor-button)) {
      min-width: 25px;
    }

    .dx-texteditor-buttons-container:before {
      pointer-events: none;
      position: relative;
      left: -8px;
      text-align: center;
      line-height: 17px;
      font-size: 13px;
      font-weight: 500;
      background-color: #f7685b;
      color: #fff;
      border-radius: 50%;
      content: "!";
      order: -2;
    }


  }

  &.dx-texteditor-empty, &.dx-invalid.dx-show-invalid-badge {
    & .dx-texteditor-container {
      & .dx-texteditor-buttons-container:before {
        position: absolute;
        left: inherit;
      }

      & .dx-texteditor-buttons-container:first-child:before {
        display: none;
      }

      & .dx-texteditor-buttons-container {
        & .dx-clear-button-area {
          display: none;
          visibility: hidden;
          pointer-events: none;
        }
      }
    }
  }

  &.dx-invalid {
    & .dx-texteditor-buttons-container {
      & .dx-clear-button-area {
        display: block !important;
      }
    }
  }

  &.dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor,
  &.dx-editor-outlined.dx-texteditor-with-label.dx-texteditor {
    @include field-border();
    margin-top: 0;

    &.blank-field {
      border-style: solid; //blank field має бути readonly але не повинен бути border-style: dotted
    }

    &.number-between-control-to {
      margin-top: 5px;
    }

    &.dx-invalid {
      @include field-border-invalid();
    }
  }

  &.color-control {
    .dx-texteditor-label {
      .dx-label {
        padding-left: 33px !important;
      }
    }
  }

  .dx-texteditor-label {
    .dx-label-before {
      min-width: 0 !important;
    }

    .dx-label-before, .dx-label, .dx-label-after {
      box-shadow: none !important;
      border: none !important;
    }

    .dx-label {
      padding: 0 8px !important;
    }

    .dx-label span {
      background-color: #fff;
      padding: 0 2px;
      color: $gray-2;
      height: 10px;
      transform: translate(0, -6.5px);
    }
  }

  &.dx-editor-outlined.dx-texteditor-with-floating-label,
  &.dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-focused,
  &.dx-editor-outlined.dx-texteditor-with-label {
    margin-top: 0;

    .dx-texteditor-label .dx-label span {
      line-height: 9px !important;
      transform: translate(0, -6.5px);
    }
  }

  &.dx-texteditor-with-floating-label.dx-texteditor-empty:not(.dx-state-focused),
  &.dx-state-readonly.dx-texteditor-with-floating-label.dx-texteditor-empty {
    .dx-texteditor-label .dx-label span {
      line-height: inherit !important;
      font-size: 13px;
      margin-top: -6.5px;
      transform: translate(0, 0);
    }
  }
}

.dx-list.dx-list-select-decorator-enabled .dx-list-item.dx-state-focused {
  .dx-radiobutton.dx-radiobutton-checked .dx-radiobutton-icon::after,
  .dx-checkbox .dx-checkbox-icon::after,
  .dx-checkbox.dx-checkbox-checked .dx-checkbox-icon::after {
    transform: scale(0);
  }
}

.dx-checkbox {
  min-width: 0;
}

.dx-checkbox-icon {
  width: 14px;
  height: 14px;
}

// Данное решение создавало баг №77796, закоментировал, баг №76531 не воспроизводится
/*.dx-texteditor-empty .dx-clear-button-area {
  // у дх в стилях display: none;, но из-за этого при отрисовке кнопки с крестиком в поле когда появляется значение,
  // поле меняет свой размер и выталкивает другие поля №0076531
  display: block;
  visibility: hidden;
  pointer-events: none;
}*/


.parent-field-container {
  display: flex;
  flex: 1;

  //у парент полях ховаємо крестики, щоб було більше місця для контенту
  .dx-clear-button-area {
    display: none !important;
  }

  &.location-label-top {
    width: 100%;
    flex: auto;
  }

  .parent-field-separator {
    width: 2px;
    background: $gray;
  }

  @include field-border();

  & > .form-field {
    flex: auto;
    margin: 0 !important;
    padding: 0 !important;

    .d5-field {
      border: none;
    }

    .buttonGroup-field__wrapper {
      height: 100%;

      .-buttonGroup {
        width: 100%;
        overflow: hidden;

        .buttons-group {
          width: 100%;

          .button-group-control {
            display: flex;
            min-height: 100%;

            .button-group-field {
              display: flex;
              flex: 1 0 auto;
              width: 100%;

              .dx-buttongroup-wrapper {
                flex-wrap: nowrap;

                .dx-button {
                  display: flex;
                  flex: 1 0 auto;
                  height: 100%;
                  border-radius: inherit;

                  &.dx-button-has-text {
                    min-width: 30px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.SingleDateRange {
  input {
    caret-color: transparent;
  }
}
