@import 'scss/ui3/tables/variables';
@import 'scss/ui3/configs/dx-list';
@import 'src/scss/ui3/mixins';


.custom-list {
  .search-field {
    padding: 0;
  }

  &.row-lines {
    .dx-list-item {
      border-top: 1px solid $gray;
    }

    .dx-list-item:last-of-type {
      border-bottom: 1px solid $gray;
    }
  }
}

@each $size in $row-sizes {
  .root-#{$size}, .root-#{$size} ~ .dx-overlay-wrapper, .root-#{$size} .dx-overlay-wrapper {
    $config: get_list_config($size);

    .custom-list {
      .dx-list-item {
        & > .dx-list-item-content {
          padding: map_get($config, 'padding-dx-item');
          font-size: map_get($config, 'font-size');

          .dx-button.dx-button-has-icon {
            @include icon_button($size)
          }
        }

        .dx-list-item-after-bag.dx-list-reorder-handle-container {
          $handler_cfg: map_get($config, 'reorder-handler');
          width: map_get($handler_cfg, 'conainer-width');

          .dx-list-reorder-handle {
            font-size: map_get($handler_cfg, 'font-size');
            line-height: map_get($handler_cfg, 'font-size');
            padding: map_get($handler_cfg, 'handler-padding');
            width: map_get($handler_cfg, 'conainer-width');
            height: map_get($handler_cfg, 'conainer-width');
          }
        }
      }
    }

    .header-filter-list {
      .dx-list-item {
        & > .dx-list-item-content {
          padding: 3px 5px 0 7px;
          height: 25px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.custom-list.row-lines {
  @include list-items-common-styles()
}

