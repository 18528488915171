@import "scss/ui3/buttons-color-scheme";

.styled-button {
  &.styling-mode-outlined {
    border: 1px solid rgba(0, 0, 0, 0.24);
  }

  &.styling-mode-text:not(.dx-state-hover, .dx-state-focused) {
    background: 0 0;
  }

  &.styling-mode-text, &.styling-mode-outlined {
    box-shadow: none;

    &.color-scheme-success {
      @include flat-dropdown-button($button-success-color);
    }

    &.color-scheme-default {
      @include flat-dropdown-button($button-default-color);
    }

    &.color-scheme-danger {
      @include flat-dropdown-button($button-danger-bg);
    }

    &.color-scheme-warning {
      @include flat-dropdown-button($button-warning-color);
    }
  }

  &.styling-mode-contained {
    &.color-scheme-success {
      @include contained-drop-down($button-success-color);
    }

    &.color-scheme-default {
      @include contained-drop-down($button-default-color);
    }

    &.color-scheme-danger {
      @include contained-drop-down($button-danger-bg);
    }

    &.color-scheme-warning {
      @include contained-drop-down($button-warning-color);
    }
  }
}
