@import "../modes";
@import "./configs/field";
@import "./configs/icon-button";
@import "./mixins";

$margin-after-search-field: 5px;
.buttons-group {
  height: 100%;
}

.dual-list-selector-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  padding: 3px;
  position: relative;
  @include field-border();

  &.state-invalid {
    @include field-border-invalid();
  }

  &.list-is-readonly {
    opacity: .38;

    .dx-list-item {
      color: rgba(0, 0, 0, 0.87) !important;
      background-color: transparent !important;
      pointer-events: none;
    }

    .dx-list-search {
      pointer-events: none;
    }
  }

  .dual-list-selector-clear-button {
    position: absolute;
    right: 30px;
    top: 18px;
  }

  .source-list, .destination-list {
    flex: 1 1 0;

    .dx-scrollable-wrapper {
      padding-right: 5px;
    }

    .list-item-container {
      position: relative;
      display: flex;
      align-items: center;

      .list-item-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1 1 auto;
      }

      .list-item-buttons-container {
        position: absolute;
        right: 0;
      }
    }
  }

  .list-arrow-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.hide-effects.dual-list-selector-wrapper {
  pointer-events: none;
}

@mixin dual-list-selector($size) {
  $fieldconfig: field_config();
  $button_cfg: get_icon_button_config($size);
  .root-#{$size}, .root-#{$size} ~ .dx-overlay-wrapper, .root-#{$size} .dx-overlay-wrapper {
    .dual-list-selector-wrapper {
      .source-list, .destination-list {

        .list-item-container {
          padding-right: map_get($button_cfg, 'widget-size');
        }
      }

      .destination-list {
        padding-top: map_get($fieldconfig, 'height') + $margin-after-search-field;
      }
    }
  }
}

@each $size in $row-sizes {
  @include dual-list-selector($size);
}
