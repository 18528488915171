@import "variables";

.pdf-preview-wrapper {
  height: 100% !important;
  display: flex;
  flex-direction: column;

  .pdf-preview__toolbar {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $gray;
    padding: 2px 0;
    column-gap: 16px;


    .pdf-preview__toolbar__item {
      display: inline-flex;
      flex-wrap: nowrap;
      align-items: center;
      column-gap: 3px;

      .pdf-preview__toolbar__item__input {
        min-width: auto;
        width: 45px !important;
      }
    }

    .button-open-in-browser {
      display: flex;
    }
  }

  .pdf-preview__Document-wrapper {
    flex: 1 1 auto;
    overflow: hidden;
    padding: 4px;

    & .dx-scrollview {
      align-self: center;
      margin: auto;
    }
  }

  .pdf-preview__Page {
    display: flex;

    .react-pdf__Page__canvas {
      image-rendering: pixelated;
      box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
      border-radius: 2px;
      margin: 0 auto;
    }
  }
}
