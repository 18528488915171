@import "scss/ui3/mixins";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #app {
  height: 100%;
  overflow: hidden;
}

.dx-button {
  &.dx-button-has-text.dx-button-mode-text .dx-button-content {
    padding: 5px 16px;
  }

  &[data-enable="true"] {
    .icon {
      color: #367FAC;
    }
  }
}

.dx-dropdownbutton {
  &:not(.dx-dropdownbutton-has-arrow) {
    .dx-button.dx-button-has-text .dx-button-content {
      padding-left: 8px;
      padding-right: 8px;
    }

    .dx-button-has-icon:not(.dx-button-has-text) .dx-button-content {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}


.dx-timeview {
  .dx-timeview-field {
    .dx-numberbox-spin-container {
      margin-right: 0 !important;
    }
  }
}

.dx-scrollable-scroll-content {
  border-radius: 50px;
}

.dx-context-menu .dx-menu-separator {
  margin: 0 !important;
}

.custom-dropdown-menu {

  .custom-dropdown-caret {
    margin-left: 5px;
  }

  .dx-menu-item .dx-menu-item-content .dx-icon {
    margin-right: 3px;
  }
}

/* END buttonsForm style */

/* Form style */

.dx-datagrid-content {
  .dx-state-disabled.dx-widget {
    opacity: .8;
  }

  .dx-texteditor.dx-editor-outlined.dx-state-disabled {
    box-shadow: unset;
  }
}

.form {
  .dx-item-content {
    max-width: 100%;

    .detail-form-cont {
      height: 600px;
    }

    > .dx-item.dx-box-item {
      max-width: 50%;
    }
  }

  .dx-tabpanel-container {
    .dx-item-content {

      .detail-form-cont {
        max-height: 400px;
      }
    }
  }
}

.dx-button-text {
  text-transform: none;
}

.dx-texteditor-buttons-container {
  div {
    &:empty {
      display: contents;
      margin-right: 7px;
    }
  }
}

.dx-show-clear-button .dx-icon-clear {
  color: black;
  height: 12px;
  width: 12px;
  font-size: 8px !important;
  font-weight: bold;
  margin-top: 0;
  left: 7px;
  transform: translate(-50%, -50%);

  &:hover {
    background-color: #D4D7E4;
  }

  &:before {
    margin-top: 0;
    margin-left: 0;
    height: 10px;
    transform: translate(-50%, -50%);
  }
}

.dx-popup-wrapper {
  .form {
    .dx-item-content {
      > .dx-item.dx-box-item {
        max-width: 100%;
      }
    }
  }
}

/* #59503
ужимается сильно в модальном окне фильтрации, на операции isanyof - закоментировал
.dx-tagbox-popup-wrapper {
  .dx-overlay-content {
    width: auto !important;
  }
}
*/

.bold {
  font-weight: bold;
}

.error-text {
  color: #d93025;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.dxx .dx-popup-content {
  padding: 0;
}

.dx-treeview-node a {
  color: inherit;
  height: 100%;
  width: 100%;
  display: block;
}

.d5-react-svg-icon {
  & > div {
    position: relative;
    height: 100%;

    svg {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

[role='checkbox'][aria-checked='false'] {
  .dx-checkbox-icon {
    border-color: #D4D7E4;
  }
}

.logo-svg-path {
  fill: url(#logo-svg-gradient);
}

.logo-svg-stop1 {
  stop-color: var(--logo-svg-stop1-color, #fff);
}

.logo-svg-stop2 {
  stop-color: var(--logo-svg-stop2-color, #fff);
}

.flex-basis-0 {
  flex-basis: 0;
}

.flex-basis-auto {
  flex-basis: auto;
}
