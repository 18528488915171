@use 'sass:map';
@import "variables";
@import "tables/mixins";
@import "configs/column-settings";

.dx-overlay-content {

  .link-buttons-wrapper.link-buttons-column-settings {
    margin: 14px 0 0 8px;

    &.tab-link {
      margin-left: 19px;
    }
    &.kanban-button-link {
      margin-left: 17px;
    }
  }

  .link-buttons-wrapper + div[class^="styles_mainContent"] {
    margin-left: -25px;
    margin-right: -18px;

    .dx-command-drag {
      &.custom-setting-drag {
        &.dx-treelist-cell-expandable {
          height: 26px;
        }
      }
    }

    .dx-command-drag {
      .dx-treelist-drag-icon {
        &:before {
          float: right;
          margin-right: -3px;
        }
      }
    }
  }
}

.filter-settings {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  .link-buttons-wrapper {
    padding: 6px 0 6px 4px;
    flex: 0 0 auto;
  }

  .dx-button-text {
    text-transform: capitalize;
    font-size: 0.75rem;
  }

  &__content {
    overflow: hidden;
  }
  &__toggle-button {
    flex: 0 0 auto;
    .dx-button-content {
      .dx-icon {
        font-size: 12px;
      }
    }
    .dx-button-text {
      float: left;
      color: $primary;
    }
  }

  &__hide {
    .dx-button-content{
      display: flex;
      align-items: center;
      margin-top: 2px;
      .dx-icon{
        float: left;
        color: $gray-2;
        margin-right: auto;
      }
    }
  }

  &__show {
    .dx-button-content{
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      .dx-icon {
        float: right;
        color: $gray-2;
        margin-left: auto;
      }
    }

  }

  &__filter-item {
    white-space: nowrap;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .caption-star-container {
    width: 10px;
    position: relative;
    margin-right: 5px;

    .caption-star {
      display: block;
      position: absolute;
      top: -2px;
      left: 2px;
    }
  }

  &__filter-checkbox {
    margin-left: 15px;
  }

  &__item-list {
    padding-right: 10px;
  }
}

@each $size in $row-sizes {
  #root.root-#{$size} .filter-settings {
    $config: column-settings_config($size);
    &__filter-item {
      font-size: map.get($config, 'row-font-size');
    }
  }
}
