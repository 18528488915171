@use 'sass:map';
@import 'tables/utils';
@import 'tables/variables';
@import 'configs/field';
@import "configs/base";

@mixin field-size($size, $config) {
  $font-size: map.get($config, 'font-size');
  $line-height: map.get($config, 'line-height');
  $input-padding: map.get($config, 'input-padding');
  $min-height: map.get($config, 'height');
  $icon-config: map.get($config, 'icons');
  $button-config: map.get($config, 'button');
  $exclamation-config: map.get($config, 'error-exclamation-sign');

  font-size: $font-size;
  line-height: $line-height;
  // min-height: $min-height;
  height: $min-height;

  input, textarea {
    padding: $input-padding !important;
    font-size: $font-size;
    line-height: inherit;
    height: auto !important;
  }

  .static-input {
    padding: $input-padding !important;
    font-size: $font-size;
    line-height: inherit;
  }

  .dx-colorbox-input {
    padding-left: 33px !important;
  }

  .dx-dropdowneditor-button {
    min-width: map.get($button-config, 'width');
    height: map.get($button-config, 'height');

    & .dx-button-content {
      & .dx-dropdowneditor-icon {
        &:before {
          color: $gray-2;
        }
      }

      &:hover {
        border-radius: 50%;
        background-color: #ebebeb;
      }
    }
  }

  .dx-button:not(.operation-type-widget) {
    &:not(.dx-buttongroup-item) {
      min-width: map.get($button-config, 'width');
      height: map.get($button-config, 'height');
      margin-right: 3px;
    }

    box-shadow: none;

    .dx-button-content {
      display: flex;
      align-items: center;
      padding: 0;
    }

    .dx-icon {
      width: map.get($icon-config, 'width');
      height: map.get($icon-config, 'height');
      font-size: map.get($icon-config, 'font-size');
      line-height: map.get($icon-config, 'height');
    }
  }

  &.multi-select-control, &.custom-tagbox {
    height: auto !important;
    min-height: $min-height;
  }

  .dx-placeholder {
    height: 100%;

    &:before {
      padding: $input-padding !important;
      font-size: $font-size;
      line-height: inherit;
      height: auto !important;
    }
  }

  .dx-texteditor-input-container::after {
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: map.get($exclamation-config, 'width') !important;
    height: map.get($exclamation-config, 'height') !important;
    font-size: map.get($exclamation-config, 'font-size') !important;
  }

  .dx-tag-container, .dx-texteditor-input-container {
    padding-top: 0;
    padding-bottom: 0;
    min-height: $min-height - 4; //- бордеры по 2пх

    &:not(:has(.dx-tag)) {
      padding: 0px 4px 0 0 !important;
    }

    .dx-tag {
      margin: map.get($config, 'tag-margin');
      //это если вложенные таг в таг
      .dx-tag {
        margin: 0;
      }

      .dx-tag-content {
        font-size: $font-size;
        line-height: $line-height;
        padding: map.get($config, 'tag-content-padding');
      }
    }
  }
}

@mixin item-margin($size) {
  $config: base_config($size);
  padding: map_get($config, 'item-margin');
}


@mixin item-margin-class($size) {
  .item-margin {
    @include item-margin($size)
  }
}


@mixin get-field-size($size) {
  $config: field_config();
  $fontSize: map.get($config, 'font-size');
  $height: map.get($config, 'height');
  .dx-button-text {
    font-size: $fontSize;
  }

  .field-label {
    font-size: $fontSize;
  }

  .decor-text {
    font-size: $fontSize;
  }

  .d5-field {
    @include field-size($size, $config);
  }

  .d5-field.radio-group-field {
    height: auto;
  }

  .d5-field.dx-state-readonly {
    .dx-tag {
      .dx-tag-content {
        padding: 5px 8px 5px 8px;

        .dx-tag-remove-button {
          display: none;
        }
      }
    }
  }
  .parent-field-container {
    min-width: 120px;

    @include field-size($size, $config);

    &:has(.dx-tag) {
      height: auto !important;
    }

    .d5-field {
      height: $height - 4; //мінус бордери
      min-width: inherit;

      .dx-texteditor-container {
        height: 100%;
      }
    }
  }

  .clear-indicator {
    font-size: map.get($config, 'clear-indicator-size');
  }

  .d5-editor-button.dx-button {
    height: 100% !important;
    padding: 0 5px;
  }
}

@each $size in $row-sizes {
  .root-#{$size} {
    @include get-field-size($size);
    @include item-margin-class($size);
  }
}

@mixin range-slider-control($size) {
  $config: base_config($size);
  $main-value: map_get($config, 'font-size');

  #root {
    &.root-#{$size} {
      .d5-range-slider {
        .dx-popup-content {
          font-size: $main-value;
          margin-top: -($main-value * 2);
        }

        .dx-slider-handle {
          &:after {
            width: $main-value;
            height: $main-value;
            margin-top: -($main-value / 2);
            margin-left: -($main-value / 2);
          }
        }

        .dx-slider-label {
          font-size: $main-value;
        }
      }
    }
  }
}

@each $size in $row-sizes {
  @include range-slider-control($size);
}
