@import "variables";
@import "mixins";
@import "tables/_variables";
@import "../prepend-slash";

.hiddenGroup {
  display: none;
}

.group-container {
  overflow: hidden;
  flex: 1 1 auto;

  @include panel-border();

  .group-caption-wrapper {
    display: flex;
    flex: 0 1 auto;
    white-space: nowrap;

    .group-caption {
      padding-left: 6px;
      @include group-caption;
    }

    &.no-border {
      @include group-caption-wrapper-no-border;
    }

    &.outlined {
      @include panel-border-caption('.group-caption');
    }

    &.hidden {
      display: none;
    }

    .hint-question-button {
      position: absolute;
      right: 2px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .group-content {
    flex: 1 1 auto;
  }
}

.grid-group-content {
  display: grid;
  overflow: hidden;
}

.popup {
  .group-item {
    &.--tab-panel {
      flex: 1 1 0;
    }

    &.--has-file-viewer {
      flex: 1 1 !important;
      flex-basis: 0;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      position: relative;
    }
  }
}


//базовые стили
.group-item {
  //стили для элементов
  &.item {
    &.--fields.--hidden {
      //flex: 0 0 auto;
      display: none;
    }

    &.--default {
      //Поставил flex-basis 0 для канбана, потому что он улетает в оверфлоу
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 0;
    }

    &.--subforms {
      flex: 1 1 0;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      position: relative;

      &.--table {
        min-height: 100px;
      }
    }

    &.--orientation-row {
      &.--fields {
        flex: 1 1 auto;
      }

      &.--decors {
        flex: 0 0 auto;
      }
    }

    &.--orientation-col {
      &.--fields {
        flex: 0 1 auto;
      }

      &.--decors, &.--resizable-items {
        flex: 1 1 auto;
      }

      &.--resizable-items {
        min-height: $medium-row-size + 10px;//10px padding top bottom

        &:has(.label-location-top) {
          min-height: $medium-row-size + 30px;//30px(10 padding top bottom + 20px висота тайтла з margin-bottom)
        }
        .d-layout-item-container {
          display: flex;
          flex-direction: column;
          overflow: hidden;

          .layout-field__wrapper {
            flex: 1 1 0 !important;
            overflow: hidden;
          }
        }

        .label-location-left, .label-location-right {
          flex-direction: row;
        }
      }
    }
  }

  .group-with-caption {
    & > .--table {
      margin-top: -$group-caption-padding;
    }
  }

  //стили для групп
  &.group {
    &.--orientation-col {
      flex: 1 0;
    }

    &.--hidden, &.--empty {
      display: none;
    }

    &.--accordion {
      max-height: 100%;
      flex: 0 0 auto;
    }

    &.--tab-panel {
      display: flex;
      overflow: hidden !important;
    }

    &.--has-file-viewer {
      flex: 1 1;
      overflow: hidden;
    }

    &.--only-small-items {
      flex: 0 1 auto;
      overflow: visible;

      &.has-group-scroll {
        overflow: hidden;
      }
    }

    &.--has-accordion {
      flex: 1 1;
      flex-basis: 0;
      min-width: 0;
      min-height: 0;
    }

    &.--edit-subform {
      max-height: 100%;
      overflow: hidden;

      &:not(.group-item--has-width) {
        flex: 1 1;
        flex-basis: 0;
      }
    }
  }
}


//группы в сплитере
.Pane {
  & > .group {
    height: 100%;
  }
}

//частные костыли
.flex-row {
  //группы расположенные строкой
  & > .group-item {
    &.--accordion:not(.group-item--has-width) {
      flex: 1 1;
      flex-basis: 0;
      overflow: hidden;
    }
  }
}

//Стили для фулскрина
.flex-full-screen-wrapper {
  overflow: hidden;
  height: 100%;
  max-height: 100%;

  .SplitPane {
    position: relative !important;

    &.horizontal {
      .flex-container {
        .flex-d5-table {
          min-height: unset;
        }
      }
    }
  }

  .d5-accordion {
    display: flex;
    overflow: hidden;
    flex-direction: column;

    .flex-column {
      & > .group-item {
        flex: 1 1 auto !important;
      }
    }
  }

  .tab-form {
    max-height: 100%;
    width: 100%;
    padding-top: 5px;

    .flex-column {
      & > .group-item {
        flex: 1 1;
        flex-basis: auto;

        &.--only-small-items {
          flex: 0 1;
          margin-top: 5px;
          overflow: visible;
        }
      }
    }
  }
}

//стили для сколл контейнера
.d5-scrollview {
  height: 100%;
  width: 100%;

  .group-container {
    min-width: 0;
    max-height: inherit;
    overflow: visible;
  }

  .group-content {
    overflow: visible;
    .group-item {
      &.--resizable-items {
        min-height: auto;
        &:has(.label-location-top) {
          min-height: auto;
        }
      }
    }
  }


  .flex-column {
    & > .group-item {
      overflow: visible !important;

      &.item.--subforms, &.group.--orientation-col, &.group.--has-accordion {
        flex: 1 1 auto !important;
      }
    }
  }
}

.help-cursor {
  cursor: help;
}

// ресет стилів кнопки очищення , задля прийнятної відстані або від сусідніх іконок або від бордерів філда

.dx-editor-filled .dx-texteditor-buttons-container > .dx-button,
.dx-editor-filled .dx-texteditor-buttons-container > .dx-clear-button-area,
.dx-editor-outlined .dx-texteditor-buttons-container > .dx-button,
.dx-editor-outlined .dx-texteditor-buttons-container > .dx-clear-button-area,
.dx-editor-underlined .dx-texteditor-buttons-container > .dx-button,
.dx-editor-underlined .dx-texteditor-buttons-container > .dx-clear-button-area {
  margin-right: 0;
}

@each $size in $row-sizes {
  #root.root-#{$size} {

    $config: get_title_config($size);

    .group-caption {
      font-size: map_get($config, 'group-font-size');
    }

    .accordion-title-wrapper {
      .accordion-title {
        font-size: map_get($config, 'group-font-size');
      }
    }

    .accordion-title-container {
      .accordion-item-title {
        font-size: map_get($config, 'group-font-size');
      }
    }

    .tab-content-wrapper {
      .tab-caption {
        font-size: map_get($config, 'group-font-size');
      }
    }
  }
}
