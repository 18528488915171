@mixin hidden-modifier {
  &-hidden {
    display: none;
  }
}

.filter-panel {
  width: 100%;
  height: 100%;
  padding: 5px 0 18px 0;
  position: relative;

  .dx-button {
    border-radius: 5px;
  }

  @media (max-width: 485px) {
    .field-label {
      font-size: 16px !important;
      max-width: calc(100% - 38px) !important; // 32px - operation-type-widget + 6px margin-left
      align-self: center !important;
      margin-bottom: 0 !important;
    }

    .operation-type-widget {
      width: 32px !important;
      min-height: 32px !important;
      margin-bottom: 5px;

      $icon-size: 24px;

      .dx-icon {
        width: $icon-size !important;
        height: $icon-size !important;
        font-size: 18px !important;

        svg {
          width: $icon-size !important;
          height: $icon-size !important;
        }
      }
    }
  }

  &__content {
    overflow: hidden;
    position: relative;

    &-visible_settings {
      height: 100%;
    }

    &-hidden_settings {
      height: auto;
      display: flex;
      flex-direction: column;
    }
  }

  .dx-button-text {
    text-transform: none;
    font-size: 0.75rem;
  }

  &__buttons-panel {
    padding: 18px 16px 0 0;

    &__button {
      min-width: 70px;
      flex: 1 1;
      display: flex;

      .dx-button {
        min-width: 70px;
        flex: 1 1;
        flex-basis: 0;
      }
    }

    .primary {
      color: $primary;
    }

    .settings {
      border-right: 1px solid $gray;
      border-radius: 0;
    }

    .clear {
      min-width: unset;
    }

    .apply {
      background-color: $primary;
      color: #fff;
      box-shadow: 0 2px 10px rgba(24, 89, 255, 0.5);
    }

    &--desktop {
      flex-wrap: wrap;

      &__button {
        padding: 5px 10px;
      }

      .desktop-button--apply {
        display: flex;
        flex-basis: 100%;
        margin-top: 8px;
      }
    }
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    overflow: hidden;
    @include hidden-modifier;
  }

  &__settings-wrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    @include hidden-modifier;
  }

  .filter-settings {
    &__show, &__hide {
      padding-left: 3px;
      padding-right: 3px;
    }
  }

  .filter-settings__toggle-button {
    &.filter-settings__toggle-back {
      margin: 10px 12px 0 0;

      .dx-button {
        .dx-button-content {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0;

          .dx-icon {
            text-align: left;
          }

          .dx-button-text {
            float: right;
          }
        }
      }
    }

    .dx-button-content {
      padding-left: 0;
      padding-right: 0;

      .dx-icon {
        margin-right: 0;
        text-align: right;
      }
    }
  }

  .filter-panel-tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $gray;
    margin-bottom: 10px;

    &_item {
      color: $gray-2;
      font-size: 16px;
      flex: 1 1;
      text-align: center;
      padding: 10px 20px;

      &.active {
        color: $dark;
        border-bottom: 2px solid $gray-2;
        font-weight: 500;
      }
    }
  }
}

.save-filter-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .08);
  height: 44px;
  margin: 10px 0;
  padding: 0 15px;

  .left-border {
    position: absolute;
    left: 0;
    width: 5px;
    height: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    &.default {
      background-color: $saved-filter-by-default;
    }

    &.favorite {
      background-color: $favorite-filter;
    }

    &.default-and-favorite {
      background-image: linear-gradient(to bottom, $saved-filter-by-default 0%, $saved-filter-by-default 50%, $favorite-filter 50%, $favorite-filter 100%);
    }
  }

  .item-container {
    width: 100%;
    height: 100%;
    display: flex;

    &_text {
      height: 100%;
      align-items: center;
      display: flex;
      flex: 1;
      color: $dark;
      text-decoration: none;
      font-size: 14px;
      outline: none;
    }
  }
}
