@import "scss/ui3/buttons-color-scheme";

.styled-dropdownbutton {
  &.styling-mode-text, &.styling-mode-outlined {
    &.color-scheme-success {
      .dx-button {
        @include flat-dropdown-button($button-success-color);
      }
    }

    &.color-scheme-default {
      .dx-button {
        @include flat-dropdown-button($button-default-color);
      }
    }

    &.color-scheme-danger {
      .dx-button {
        @include flat-dropdown-button($button-danger-bg);
      }
    }

    &.color-scheme-warning {
      .dx-button {
        @include flat-dropdown-button($button-warning-color);
      }
    }
  }

  &.styling-mode-contained {
    &.color-scheme-success {
      .dx-button {
        @include contained-drop-down($button-success-color);
      }
    }

    &.color-scheme-default {
      .dx-button {
        @include contained-drop-down($button-default-color);
      }
    }

    &.color-scheme-danger {
      .dx-button {
        @include contained-drop-down($button-danger-bg);
      }
    }

    &.color-scheme-warning {
      .dx-button {
        @include contained-drop-down($button-warning-color);
      }
    }
  }
}
