@use "sass:map";
@import "variables";
@import "mixins";
@import "tables/variables";
@import "table-toolbar-mixin";


.horizontal-separator {
  width: 2px;
  height: 30px;
  background: #D4D7E4;
}

.table-toolbar-wrapper {
  display: flex;
  flex: 0;
  align-items: center;

  .dx-button-content {
    display: flex;
    align-items: center;

    .dx-icon {
      padding: 0 !important;
      margin-right: 0 !important;
    }

    & div:first-child {
      display: inline-flex;
      align-items: center;

      & .dx-icon:not(img) {
        align-items: center;
        height: 100% !important;
        width: auto;
      }

      & .dx-button-text {
        padding-left: 4px;
      }
    }
  }

  .d5-table-toolbar {
    width: 100%;
    padding: 8px 0;

    .toolbar-item {
      display: flex;
      margin-right: 2px;

      &:last-of-type {
        margin-right: 0;
      }

      &.section-item {
        border: $dark-30 1px solid;
        border-left-width: 0;
        margin-right: 0;
      }

      &.section-item-first {
        border-left-width: 1px;
        border-bottom-left-radius: 2px;
        border-top-left-radius: 2px;
        margin-left: 2px;
      }

      &.section-item-last {
        border-bottom-right-radius: 2px;
        border-top-right-radius: 2px;
        margin-right: 2px;
      }
    }

    .horizontal-separator {
      margin-right: 6px;
      margin-left: 5px;
    }

    .d5-is-pressed {
      i, span {
        color: $primary !important;
      }

      path {
        fill: $primary;
      }
    }

    .d5-react-svg-icon > div {
      width: 16px;
    }

    .dx-dropdownbutton {
      .d5-react-svg-icon > div svg {
        max-width: 16px;
        max-height: 16px;
      }
    }

    .toolbar-button, .toolbar-group-button, .dx-dropdownmenu-button {
      border-radius: 2px !important;

      & .dx-button-text {
        padding-left: 4px;
        max-width: 100px;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .dx-dropdownmenu-button {
      .dx-icon {
        transform: rotate(90deg);
      }
    }

    .dx-item[aria-label='far fa-filter'] {
      .dx-button-content {
        padding-right: 0;
        padding-left: 5px;
      }

      + .dx-item {
        .dx-icon {
          margin-right: 2px;
        }
      }
    }

    .dx-button-has-text {
      .dx-button-content {
        padding: 4px;
      }
    }

    .toolbar-group-button {
      //если кнопка сплитнутая например кнопка отображения фильтра, то в ней рисуются
      //вложенные две кнопки вместо двух иконок
      .dx-dropdownbutton-toggle {
        .dx-button-content {
          flex: 0;
          padding: 0;
          width: 16px;
        }
      }
      & .dx-buttongroup-wrapper .dx-button-content {
        display: flex;
        align-items: center;
        padding: 0 0 0 5px;

        .dx-icon:not(.dx-icon-spindown) {
          padding-right: 5px;
        }

        .dx-icon-spindown {
          height: 24px !important;
          width: 16px !important;

          &:before {
            font-size: 20px !important;
            height: 24px;
            position: relative;
            top: 3px;
          }
        }
      }
    }
    .toolbar-group-button.split-drop-down-btn .dx-buttongroup-wrapper .dx-button-content {
      padding: 0 5px 0 5px;
    }

    .dx-buttongroup-wrapper:hover .dx-widget.dx-button .dx-button-content {
      background-color: $primary-15;
    }

    .dx-buttongroup-wrapper:hover .dx-widget.dx-button.dx-dropdownbutton-toggle .dx-button-content {
      background-color: #B3C5F6;
      color:#1453F8;

      .dx-icon-spindown {
        &:before {
          color:#1453F8;
        }
      }
    }


    .dx-toolbar-items-container {
      height: $table-toolbar-height;
    }
  }

  .row-size-button {
    .dx-button-content {
      .dx-icon {
        margin-right: 0;
        line-height: 18px;
      }

      .dx-button-text {
        display: none;
      }
    }
  }

  .row-size-button,
  .column-settings-button {

    &.dx-state-focused {
      background-color: rgba(0, 0, 0, 0);
    }

    @include toolbar-settings-button();
  }
}

.tab-settings-button {

  &.dx-state-focused {
    background-color: rgba(0, 0, 0, 0);
  }

  @include toolbar-settings-button();
}
.tab-settings-button-container {
  display: flex;
  align-items: center;
}
.dx-multiview-items-container{
  flex: 1;
  overflow: hidden;
}

.dx-dropdownmenu-popup-wrapper {
  & > div {
    border-radius: 5px !important;
  }
}

//меню в тулбаре там где три точки
.dropdown-list-wrapper {
  @each $size in $row-sizes {
    $config: cm_config();
    &.row-size-#{$size} {
      //каждый элемент списка должен быть подчеркнут
      & > .dx-list-item {
        border-bottom: 1px solid rgba(102, 102, 102, 0.08);

        &:last-child {
          border-bottom: none;
        }

        &.dx-state-disabled {
          opacity: 1 !important;
        }
      }

      .hidden-menu-button, .toolbar-button {
        width: 100%;
        height: map.get($config, 'height') !important;

        .disabled {
          opacity: 1 !important;
        }

        .dx-button-content {
          padding: 0 8px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
      }

      //все иконки
      i, svg {
        @include toolbar-icon();
      }

      //весь текст
      span {
        @include toolbar-hidden-menu-text($config);
      }

      .dx-button-content {
        & div:first-child {
          display: flex;
          align-items: center;

          & .dx-button-text {
            padding-left: 4px;
          }
        }
      }

      .hidden-menu-button {
        .toolbar-group-button {
          padding: 0;

          & i {
            color: rgb(110, 116, 146);
          }
        }

        .dx-button-content {
          padding: 0 8px !important;
        }
      }
    }
  }
}

@each $size in $row-sizes {
  #root.root-#{$size} {

    $config: get_title_config($size);
    $config: get_icon_config();

    .table-toolbar-wrapper {
      .d5-table-toolbar {
        .toolbar-button, .toolbar-group-button, .dx-dropdownmenu-button {
          height: map_get($config, 'table-toolbar-height');
          min-width: map_get($config, 'table-toolbar-height');
        }
      }
    }

    .mobile-form-toolbar-wrapper {
      .d5-table-toolbar {
        .toolbar-item {
          .dx-button.d5-is-pressed {
            .dx-icon > svg {
              color: $white !important;
            }
          }

          .dx-button {
            .dx-icon > svg, i {
              font-size: 18px;
              height: 20px;
              width: 20px;
            }
          }
        }

        .toolbar-item:not(.disabled) {
          .dx-button:not(.d5-is-pressed) {
            .dx-icon > svg, i:not(.dx-icon-spindown) {
              color: $primary !important;
              path {
                fill: $primary !important;
              }
            }
          }
        }

        .other-items-button {

        }
      }
    }

    :not(.mobile-form-toolbar-wrapper) {
      // @include toolbar-button(map_get($config, 'toolbar-icon-size'));
      @include toolbar-button($toolbar-icon-size);
    }
  }
}
