@import "variables";

$topbar-height: 42px;
$mobile-topbar-height: 48px;
$topbar-item-color: $white;
$top-bar-open-button-bg: $primary-110;

@mixin toolbar-size($set-width: false) {
  height: $topbar-height;
  @if $set-width {
    width: $topbar-height;
  }

  @media (max-width: 700px) {
    height: $mobile-topbar-height;
    @if $set-width {
      width: $mobile-topbar-height;
    }
  }
}

.topbar-item {
  color: $topbar-item-color;

  .dx-item {
    border-radius: 50% !important;
  }

  .dx-button-text {
    color: $white;
  }

  .dx-button-has-text .dx-button-content {
    overflow: visible;
  }

  .dx-icon {
    color: $topbar-item-color;
  }
}
.topbar-item.profile-button{
  .dx-button-has-text .dx-button-content {
    padding-top: 3px;
  }
}
.profile-button-dropdown {
  div[title] {
    .dx-list-item-icon-container {
      height: unset;
    }
    &.dx-state-disabled {
      .dx-list-item-content, .dx-list-item-icon {
        color: $gray;
      }
    }
    &.dx-list-item-selected  {
      background-color: transparent !important;

      &.dx-state-hover {
        background-color: rgba(0, 0, 0, 0.04) !important;
        color: $secondary !important;
      }
    }
  }
}

@media (max-width: 960px) {
  .qr-control__header {
    padding: 10px;

    i {
      margin-right: 10px;
    }
  }
}
