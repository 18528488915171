@use 'sass:map';
@import 'scss/modes';

.dx-overlay-wrapper {

  &.dx-dropdownlist-popup-wrapper {
    .dx-overlay-content {
      min-width: 180px !important;
    }
  }
  .dx-overlay-content {
    overflow: visible;
    border-radius: 5px;

    // base popup padding
    .dx-popup-content:not(.d5-hint) {
      padding-bottom: 5px;
      padding-right: 10px;
      padding-left: 10px;
    }

    .dx-popup-content:not(.d5-hint):not(.dx-diagram-toolbox-popup *) {
      padding: 5px 10px;
    }

    .dx-popup-content:not(.d5-hint) {
      background: #fff;

      .dx-item-content {
        font-size: $medium-font-size;
      }
    }

    .dx-list-items .dx-list-item {
      &.dx-state-focused {
        .dx-checkbox-icon {
          border-color: $focused-color;
        }
      }
  
      &[aria-selected="true"] {
        &.dx-state-focused {
          .dx-checkbox-icon {
            border: 2px solid $focused-color;
          }
        }
      }
    }

    @media (max-width: 480px) {
      .dx-toolbar .dx-toolbar-items-container .dx-button-content {
        padding: 5px; // в popup datatime кнопки в toolbar злипаються
      }
    }
  }
  &.dx-diagram-toolbox-popup {
    z-index: 998 !important;
  }
}