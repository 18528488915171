@import 'variables';
@import "mixins";
@import "mixins";
@import "tables/_variables";
@import "../prepend-slash";

//стили таб панели
.tab-form {
  display: flex;
  flex: 1 1 auto;

  & > .dx-tabpanel-tabs .tabs-position-top,
  & > .dx-tabpanel-tabs .tabs-position-bottom {
    height: 36px;
  }
  & > .dx-tabpanel-tabs .tabs-position-left,
  & > .dx-tabpanel-tabs .tabs-position-right {
    height: 100%;
  }

  &.tab-column-full-width {
    .dx-tabs-wrapper {
      display: flex;
    }
    > .dx-tabpanel-tabs .dx-tab {
      flex: 1;
    }
    .dx-tabs-nav-button.dx-button.dx-tabs-nav-button.dx-button-has-icon:not(.dx-button-has-text).dx-state-disabled {
      z-index: 1;
    }
    .dx-tabs-scrollable {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .tabs-wrapper{
    display: flex;

  }
  &.with-tab-settings-button {
    & > .dx-tabpanel-tabs {
      display: flex;
      @include disabled-nav-tab-button;

      & > .dx-tabs {
        width: calc(100% - 32px);
      }
    }
    & > .dx-tabpanel-tabs.tabs-position-left,
    & > .dx-tabpanel-tabs.tabs-position-right,{
      & > .dx-tabs {
        width: 100%;
        height: calc(100% - 32px);
      }
    }
  }

  //заголовки

  & > .dx-tabpanel-tabs {
    // панель под вкладками
    & > .dx-tabs {
      background: transparent;
    }

    .dx-tab {
      // высота 30
      padding: 8px 17px;
      // Робимо такий базис, бо в DX дефолтно 100% і тому таби розтягуються на усю ширину
      flex-basis: auto;
      height: 36px;
      max-height: 36px;
      flex-grow: 0;

      .dx-tab-content {
        .tab-content-wrapper {
          display: flex;
          align-items: center;
        }
      }

      .dx-tab-text, .tab-caption {
        padding-left: 7px;
        @include group-caption;
        @include group-caption-tab;
      }

      //активный таб
      &.dx-tab-selected {
        background: transparent;

        &:after {
          z-index: 1;
          height: 3px;
        }

        .dx-tab-text, .tab-caption {
          @include group-caption-active;
          @include group-caption-tab;
        }
      }
    }
  }
  & > .dx-tabpanel-tabs.tabs-position-left {
    @include group-caption-wrapper-custom(left);
    @include vertical-tab-separates($gray-7-alpha);
    flex-direction: column;
    padding-right: 10px;

      .dx-tab-content {
        .tab-content-wrapper {
          max-width: 166px;
          flex-direction: column;
          .tab-caption {
            padding-top: 5px;
          }
        }
      }
    .tab-settings-button-container{
      justify-content: center;
      padding: 2px;
      border-right: 1px solid $lightBorderPanelColor;
    }
  }


  & > .dx-tabpanel-tabs.tabs-position-right {
    @include group-caption-wrapper-custom(right);
    @include vertical-tab-separates($gray-7-alpha);
    flex-direction: column;
    padding-left: 10px;

    .dx-tab-content {
      .tab-content-wrapper {
        max-width: 166px;
        flex-direction: column;
        .tab-caption {
          padding-top: 5px;
        }
      }
    }
    .tab-settings-button-container{
      padding: 2px;
      justify-content: center;
      border-left: 1px solid $lightBorderPanelColor;
    }

  }

  & > .dx-tabpanel-tabs.tabs-position-left.with-icons-and-title-tabs,
  & > .dx-tabpanel-tabs.tabs-position-right.with-icons-and-title-tabs{
    .dx-tab {
      height: 54px;
      max-height: 54px;
    }
  }
  & > .dx-tabpanel-tabs.tabs-position-bottom {
    @include group-caption-wrapper-custom(bottom);
  }
  & > .dx-tabpanel-tabs.tabs-position-top {
    @include group-caption-wrapper-custom(top);
  }

  &.dx-tabpanel-tabs-position-top >  .dx-tabpanel-container {
    //высота тулбара в среднем размере интерфейса
    $medium-toolbar-height: 36px;
    //переопределяем здесь отступы ДХ потому что они считают их асинхронно и в какой-то момент не правильно высчитываются (типа пустой тулбар)
    margin-top: -$medium-toolbar-height !important;
    padding-top: $medium-toolbar-height !important;
    height: 100%;
    overflow: hidden;
  }

  .group-container {
    height: inherit;
  }
}

@each $size in $row-sizes {
  #root.root-#{$size} {
    $config: get_icon_config();

    .tab-form {
      //заголовки
      & > .dx-tabpanel-tabs {
        .dx-tab-content {
          @include icon(null, map_get($config, 'tab-icon-size'));
        }
      }
    }
  }
}
