@use "sass:map";
@import "_variables";
@import "mixins";
@import "utils";

@mixin data-row-size($config) {
  .dx-treelist, .dx-datagrid {
    //ячейки с данными
    .dx-row.dx-data-row, .table-total-row {
      td {
        height: map.get($config, 'height');
        vertical-align: top !important;

        & > span:not(:empty) {
          display: block;
        }

        .dx-treelist-text-content {
          overflow: visible;
        }

        &:not(.dx-editor-cell) {
          font-weight: 400;
          @include table-data-cell-size($config);

          i, .dx-icon {
            line-height: inherit;
          }
        }

        .dx-treelist-collapsed {
          top: -4px;
        }
        .right {
          justify-content: flex-end;
        }
        .center {
          justify-content: center;
        }
        .left {
          justify-content: left;
        }

        &.dx-command-drag {
          $padding: map.get($config, 'padding');

          padding-top: $padding - 2;
          padding-bottom: $padding - 1px;
        }
      }
    }

    .dx-row.dx-selection {
      td:not(.dx-focused):not(.d5-selected-cell) {
        background-color: $secondary !important;
        color: rgba(255, 255, 255, 0.8) !important;
      }
    }
    // Тут регулюємо зняття виділення зі строки якщо налаштований матрас
    .dx-row-alt:not(.marked-node):not(.dx-selection) > td:not(.dx-focused):not(.d5-selected-cell) {
      background-color: $gray-6;
    }

    .dx-row.dx-data-row {
      td {
        &.d5-buttons-panel, &.dx-command-drag {
          .dx-datagrid-drag-icon {
            font-size: map.get($config, 'font-size') + 2px;
          }
        }
      }
    }
  }
}

.table-wrapper {
  .vertical-align-top {
    .dx-data-row > td {
      vertical-align: top !important;
    }
  }

  // размеры ячеек height=30px
  .dx-treelist, .dx-datagrid {

    .dx-datagrid-revert-tooltip .dx-revert-button {
      display: none;
    }

    .dx-texteditor-buttons-container {
      .dx-button-content {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    //ячейки с данными
    .dx-data-row {
      td {
        &.d5-buttons-panel {
          padding: 0 !important;
        }
      }

      //картинка в ячейке
      .row-img-block {

        img {
          width: 50px;
          height: auto;
        }
      }

      //ссылка в ячейке
      .row-link-block {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .column-switch-wrapper {
        height: 14px;

        .switch-svg {
          width: 22px;
        }

        &.state-readonly {
          .switch-svg-rect {
            opacity: 0.5;
          }

          .switch-svg[data-active=false] {
            .switch-svg-path {
              opacity: 0.8;
            }
          }
        }
      }

      .dx-buttongroup-wrapper {
        justify-content: center;
        height: 20px;

        .dx-button {
          height: 20px;

          .dx-button-content {
            padding-left: 5px;
            padding-right: 5px;
          }
        }

        .dx-button-has-text {
          width: 30px;
        }

        .dx-item-selected {
          color: #fff;
          background-color: #34AFF9;
        }
      }
    }


    //ячейки с данными в невыделенной строке
    .dx-row.dx-data-row:not(.dx-selection):not(.dx-row-lines) > td {
      border-bottom: none;
      border-top: none;
      color: $table-cell-color;
    }

    .fixed-columns-summary-wrapper {
      padding-right: 0;
    }
  }
}
