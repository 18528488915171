@use "sass:math";

@import "_variables";
@import "mixins";
@import "utils";
@import "../../prepend-slash";

@mixin fa-icon {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
}

@mixin header-cell-size($config) {
  .dx-treelist, .dx-datagrid {
    .dx-row.dx-header-row > td {
      cursor: pointer;
      @include table-header-cell-size($config);
    }

    td.vertical-header-container {
      padding: 0 !important;

      .dx-column-indicators {
        .dx-header-filter, .dx-sort {
          padding: 8px 1px !important;
          height: 30px;
        }
      }
    }

    .dx-treelist-table {
      & .drag-icon, .dx-sort {
        bottom: 1px;
      }
    }
  }
}

@mixin header-header-icon {
  $size: 10px;
  font-size: $size !important;
}

@mixin custom-duo-fa-icon($primary-color: inherit, $primary-opacity: 1, $secondary-color: inherit, $secondary-opacity: 0.4) {
  position: relative;
  font-family: "Font Awesome 6 Duotone";
  font-weight: 900;

  @include fa-icon;

  &:before {
    font-family: "Font Awesome 6 Duotone";
    position: absolute;
    color: $primary-color;
    opacity: $primary-opacity;
  }

  &:after {
    font-family: "Font Awesome 6 Duotone";
    color: $secondary-color;
    opacity: $secondary-opacity;
  }
}

@mixin icon-hover() {
  &:hover {
    transform: scale(1.25);
  }
}

.table-wrapper {
  // размеры ячеек height=30px
  .dx-treelist, .dx-datagrid {
    .dx-datagrid-headers,
    .dx-treelist-headers {
      background-color: $table-header-cell-bg;

      // чекбокс в хедере таблицы
      .dx-treelist-select-all {
        padding-left: 7px !important;

        .dx-checkbox-container {
          margin-left: 17px;
        }

        .dx-checkbox {
          position: static;
        }
      }
    }

    .dx-header-multi-row {
      .dx-treelist-table-fixed, .dx-datagrid-table-fixed {
        height: 100%; // якщо не встановити, то в заголовках з групами не встановлюється на всю висоту
      }
    }

    // заголовки в таблицах
    .dx-row.dx-header-row > td {
      font-weight: 700;
      color: $table-header-cell-color !important;
      background-color: $table-header-cell-bg;
      box-shadow: inset 0px -1px 0px $gray;

      @-moz-document url-prefix() {
        background-clip: padding-box;
      }

      &:hover {
        background-color: #ececee !important;
      }
    }

    .dx-row.dx-header-row {
      td {
        position: relative;

        //все иконки в хедере
        .drag-icon,
        .dx-sort-none,
        .dx-sort-up,
        .dx-sort-down,
        .dx-header-filter-empty {
          color: $gray-2;
          width: 16px;
          height: 16px;
          text-align: center;
          transition: transform 250ms;

          &:hover {
            @include icon-hover;
          }
        }

        .dx-datagrid-text-content:not(.dx-sort-indicator), 
        .dx-treelist-text-content:not(.dx-sort-indicator) {
          max-width: 100%;
        }

        .dx-datagrid-text-content, .dx-treelist-text-content {
          // max-width: calc(100% - 6px); //минус марджин слева и справа
          max-width: calc(100% - 16px);
          margin: 0;
        }

        //для булевых колонок проставляется сразу два класса и они ставят марджины и иконка сортировки слетает вниз
        .dx-text-content-alignment-left.dx-text-content-alignment-right {
          margin: 0;
        }

        //если включена только одна иконка
        &[data-has-filter=true], &[data-has-sort=true] {
          .dx-sort-indicator, .dx-datagrid-text-content, .dx-treelist-text-content {
            // max-width: calc(100% - 30px);
            max-width: calc(100% - 16px);
            &.dx-sort-index-indicator {
              max-width: calc(100% - 28px);
            }
          }
        }

        // если включено сразу 2 иконки
        &[data-has-filter=true][data-has-sort=true] {
          .dx-sort-indicator, .dx-datagrid-text-content, .dx-treelist-text-content {
            // max-width: calc(100% - 38px);
            max-width: calc(100% - 32px);
            
            &.dx-sort-index-indicator {
              max-width: calc(100% - 42px);
            }
          }
        }

        &.dx-treelist-select-all {
          .dx-datagrid-text-content, .dx-treelist-text-content {
            max-width: calc(100% - 34px);
          }

          &[data-has-filter=true], &[data-has-sort=true] {
            .dx-datagrid-text-content, .dx-treelist-text-content {
              max-width: calc(100% - 48px);

              &.dx-sort-index-indicator {
                max-width: calc(100% - 58px);
              }
            }
          }
          &[data-has-filter=true][data-has-sort=true] {
            .dx-datagrid-text-content, .dx-treelist-text-content {
              max-width: calc(100% - 64px);

              &.dx-sort-index-indicator {
                max-width: calc(100% - 74px);
              }
            }
          }
        }
      }

      .vertical-header-container {
        position: relative;
        vertical-align: bottom !important;

        .dx-column-indicators {
          position: relative;
          margin-left: auto;
          margin-right: auto;
          text-align: center;
          float: inherit !important;
          height: 100% !important;
          min-height: 42px;
          flex-direction: column-reverse;
          justify-content: flex-end;
          align-items: center;
          margin-top: 8px;

          .dx-sort {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
          }

          .dx-header-filter {
            padding-top: 0 !important;
          }
        }

        .drag-icon {
          position: absolute;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          bottom: 0;
          text-align: center;
        }

        .vertical-header {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          writing-mode: vertical-rl;
          transform: rotate(180deg);
          max-width: 100% !important;
          width: 100%;
          height: 100%; // якщо не встановити, то фіксовані заголовки не встановлюються на всю висоту (з 'auto' будуть проблеми)
          max-height: 120px;
          margin-bottom: 5px !important;
          padding: 10px 0 0 0 !important;
          position: relative;
          bottom: 0;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto !important;
          text-align: center;

          & > span {
            writing-mode: vertical-rl;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-left: 0;
            padding-bottom: 8px;
          }
        }

        .column-required-icon-space {
          position: absolute;
          bottom: 0;
          right: 50%;
          transform: translateX(-50%);
        }
      }

      //какая-то дх херня которая место просто забирает при ховере
      .dx-column-indicators.dx-visibility-hidden {
        display: none;
      }

      //иконка драг и дропа
      .drag-icon {
        position: absolute;
        left: 2px;
        display: none;
        font: 14px/1 DXIcons;

        &:before {
          content: prepend-slash("f038");
        }
      }

      //иконка фильтра
      .dx-header-filter-empty {
        order: 1;
        display: none;
        @include header-header-icon;
        @include fa-icon-solid;
        float: right;

        &[data-active='true'] {
          display: inline-block;
        }

        &:before {
          content: prepend-slash("f0b0");
          //добавлено для увеличения кликабельной области
          padding: 0 5px 0 2px;
        }

        &:after {
          margin: 0;
        }
      }

      .dx-sort {
        order: 0;
      }

      //иконка сортировка вверх
      .dx-sort.dx-sort-up {
        @include custom-duo-fa-icon($primary);

        &:before {
          content: prepend-slash("f0de");
        }

        &:after {
          content: prepend-slash("10f0de");
        }
      }

      //иконка сортировка вниз
      .dx-sort.dx-sort-down {
        @include custom-duo-fa-icon($primary);

        &:before {
          content: prepend-slash("f0dd");
        }

        &:after {
          content: prepend-slash("10f0dd");
        }
      }

      .dx-column-indicators {
        float: right !important;
        display: flex;
        column-gap: 1px;

        & .dx-sort, .dx-header-filter, .drag-icon {
          height: auto !important;
        }
      }

      .dx-sort {
        &.dx-sort-down.dx-sort-index, &.dx-sort-up.dx-sort-index {
          width: 26px !important;
          float: left;
          margin: 0 1px 0 -1px;
        }
      }

      //индекс мультисортировки
      .dx-sort-index-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        float: right;
        width: 12px;
        height: 12px;
        top: 1px;
        right: 0;
        margin: 0;
        padding: 0;
        border-radius: 2px;
        font-size: 10px;
        background: $secondary-purple;
        color: $white;
        text-align: center;
        vertical-align: middle;
        font-weight: bold;
      }

      //иконка сортировка пусто
      .dx-sort.dx-sort-none {
        @include custom-duo-fa-icon;
        display: none;

        &:before {
          content: prepend-slash("f0dc");
        }

        &:after {
          content: prepend-slash("10f0dc");
        }
      }

      td.template-cell:hover {
        .drag-icon,
        .dx-sort-none,
        .dx-header-filter-empty {
          display: inline-block;
        }

        .dx-datagrid-text-content:not(.dx-text-content-alignment-right, .dx-text-content-alignment-center),
        .dx-treelist-text-content:not(.dx-text-content-alignment-right, .dx-text-content-alignment-center) {
          ~ .column-required-icon-space {
            position: absolute;
          }

          // position: relative;
          // padding-left: 10px;
        }

        .dx-datagrid-text-content.without-indicators,
        .dx-treelist-text-content.without-indicators {
          max-width: 100%;
        }

        .dx-datagrid-text-content, .dx-treelist-text-content, .dx-sort-indicator {
          margin: 0;
          max-width: calc(100% - 32px);

          &.dx-sort-index-indicator {
            max-width: calc(100% - 42px);
          }
        }

        .dx-datagrid-text-content.with-indicators,
        .dx-treelist-text-content.with-indicators {
          // max-width: calc(100% - 32px);
          &.dx-sort-index-indicator {
            max-width: calc(100% - 42px);
          }
          // max-width: calc(100% - 44px);
        }

        .dx-datagrid-text-content.one-indicator,
        .dx-treelist-text-content.one-indicator{
          max-width: calc(100% - 16px);
          &.dx-sort-index-indicator {
            max-width: calc(100% - 28px);
          }
          // max-width: calc(100% - 18px);
        }

        .dx-text-content-alignment-left.one-indicator,
        .dx-text-content-alignment-right.one-indicator {
          position: relative;
          // padding-left: 10px;
          // padding-right: 5px;
        }

        &.dx-treelist-select-all {
          .dx-datagrid-text-content, .dx-treelist-text-content {
            &.with-indicators {
              max-width: calc(100% - 64px);
            }

            &.one-indicator {
              max-width: calc(100% - 44px);
            }

            &.dx-sort-index-indicator {
              max-width: calc(100% - 74px);
            }
          }
        }

      }
    }
  }
}

.mobile-container {
  .table-wrapper {
    //заборонити клік по лійці фільтру на мобільному
    .dx-row.dx-header-row .dx-header-filter {
      pointer-events: none;
    }

    td:hover {
      .dx-header-filter-empty {
        &[data-active='false'] {
          display: none !important;
        }
      }
    }
  }
}

@function get_checkbox-margin($size) {
  $small-config: ('margin': 2px 5px 0 0);
  $medium-config: ('margin': 7px 5px 0 0);
  $large-config: ('margin': 12px 5px 0 0);

  $config: $medium-config;

  @if $size == 'small' {
    $config: $small-config;
  } @else if $size == 'large' {
    $config: $large-config;
  }
  @return $config;
}

@each $size in $row-sizes {
  #root.root-#{$size} ~ .table-wrapper, #root.root-#{$size} .table-wrapper {

    $config: get_checkbox-margin($size);

    & .dx-treelist-select-all {
      & .dx-checkbox {
        margin: map_get($config, 'margin');
      }
    }
  }
}
