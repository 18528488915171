@import "scss/ui3/variables";

$tableMinHeight: 200px;
$selectionCheckboxColumnWidth: 70px;

.svg-boolean-column-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;

}

.svg-boolean-column-alignment-checkbox {
  display: block;
}

.svg-boolean-column-checkbox,
.svg-boolean-column-alignment-checkbox {
  svg {
    width: 14px;
  }
}

td.dx-focused:not(.dx-editor-cell):not(.dx-datagrid-invalid):not(.dx-treelist-invalid),
td.d5-selected-cell:not(.dx-editor-cell):not(.dx-datagrid-invalid):not(.dx-treelist-invalid) {
  background-color: $secondary-medium-transparent !important;
  color: black !important;
}

.flex-form-edit-table {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 0;
  position: relative;
  height: 100%;

  .total-row {
    &-hidden-borders {
      border-bottom: none !important;
    }

    &-dragdrop-margin {
      //38px ширина колонки drag&drop
      padding-left: 38px;
    }
  }

  .flex-container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;

    .flex-d5-table {
      width: 100%;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: $tableMinHeight;

      .dx-icon:not(.command-button) {
        font-size: inherit;
      }

      & > div {
        flex: 1 1 auto;
      }

      .dx-scrollable-content {
        padding-bottom: 0 !important;
        padding-right: 0 !important;
      }
    }

    .auto-width {
      width: auto;
    }

    // перестиливание размера колонки с чекбоксом
    .dx-command-select {
      width: $selectionCheckboxColumnWidth !important;
      min-width: $selectionCheckboxColumnWidth !important;
      padding: 0 !important;
    }
  }
}

.revert-tooltip-is-hidden {
  .dx-datagrid-revert-tooltip {
    display: none;
  }
}

.dx-datagrid, .dx-treelist, .dx-treelist-container {

  .total-row {
    border-top: 2px solid $gray;
  }

  .column-required {
    &__visible {
      display: inline;
    }

    &__hidden {
      display: none;
    }
  }

  .dx-error-row {
    display: none;
  }

  //context menu
  .dx-menu-item {
    .dx-icon {
      margin-right: 5px;
    }

    .dx-icon-context-menu-sort-none {
      display: none !important;
    }
  }

  .dx-datagrid-bottom-load-panel {
    display: none;
  }

  .dx-datagrid-header-panel, .dx-treelist-header-panel {
    display: none;
    border-bottom: none;

    .dx-datagrid-column-chooser-button, .dx-treelist-column-chooser-button {
      display: none;
    }
  }

  .dx-datagrid-total-footer {
    .dx-datagrid-summary-item {
      white-space: nowrap;
      font-weight: bold;
    }
  }

  &.click-selection-mode {
    .dx-treelist-icon-container {
      max-height: 13px;
      padding-right: 3px;
    }

    .dx-select-checkbox {
      display: none;
    }
  }


  .dx-datagrid-headers, .dx-treelist-headers {

    .dx-header-row {
      font-weight: bold;

      & [role="columnheader"] {
        padding-right: 6px;
      }
    }

    .dx-datagrid-table, .dx-treelist-table {
      .dx-datagrid-filter-row, .dx-treelist-filter-row {
        .dx-filter-range-content {
          display: none;
        }

        .d5-field-container {
          width: 100%;


          .multi-select {
            .dx-placeholder {
              &:before {
                padding-left: 0 !important;
              }
            }

            .dx-texteditor-input {
              padding-left: 0;
            }
          }

          .dx-dropdowneditor {
            margin: 0;
            padding: 0;
          }

          .field-container-between {
            .dx-texteditor {

              .dx-texteditor-input {
                min-height: 30px;
              }
            }
          }
        }
      }
    }
  }
}

.table-header-contextmenu-item {
  &.hide-column {
    font-size: 14px;
  }
}

.dx-overlay-content {
  .dx-popup-content {

    .dx-searchbox {
      .dx-texteditor-container {

        .dx-icon-search {
          font: 14px/1 DXIcons;
          position: absolute;
          top: 50%;
          margin-top: -12px;
          width: 24px;
          height: 24px;
          background-position: 5px 5px;
          -webkit-background-size: 14px 14px;
          -moz-background-size: 14px 14px;
          background-size: 14px 14px;
          padding: 5px;
          text-align: center;
          line-height: 14px;
          font-size: 13px;
          color: #999999;
        }

        .dx-texteditor-input {
          padding: 4px 24px;
          font-size: 1em;
          border-radius: 4px;
          min-height: 24px;
          line-height: 1.33334;
        }

        .dx-placeholder {
          &:before {
            padding: 4px 5px 4px 24px;
          }
        }

        .dx-texteditor-buttons-container {
          .dx-clear-button-area {
            width: 24px;

            .dx-icon-clear {
              color: #999999;
              position: absolute;
              top: 50%;
              margin-top: -12px;
              width: 24px;
              height: 24px;
              background-position: 5px 5px;
              -webkit-background-size: 14px 14px;
              -moz-background-size: 14px 14px;
              background-size: 14px 14px;
              padding: 5px;
              font-size: 14px;
              text-align: center;
              line-height: 14px;
            }
          }

        }

      }
    }
  }

}
