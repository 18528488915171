@import "variables";

@mixin horizontal-resizer-icon($position) {
  content: '';
  background-image: url("../../../public/icons/white-chevron-right.svg");
  background-repeat: no-repeat;
  width: 7px;
  height: 11px;
  position: absolute;
  top: calc(50% - 5px);
  left: 2px;
  @if $position == 'left' {
    transform: rotate(180deg);
  } @else if $position == 'right' {
    transform: rotate(0deg);
  } @else if $position == 'top' {
    transform: rotate(-90deg);
  } @else if $position == 'bottom' {
    transform: rotate(90deg);
  }
  cursor: pointer;
}

@mixin vertical-resizer-icon($position) {
  content: '';
  background-image: url("../../../public/icons/white-chevron-down.svg");
  background-repeat: no-repeat;
  width: 11px;
  height: 9px;
  position: absolute;
  left: calc(50% + 2px);
  top: -5px;
  @if $position == 'left' {
    transform: rotate(180deg);
  } @else if $position == 'right' {
    transform: rotate(0deg);
  } @else if $position == 'top' {
    transform: rotate(180deg);
  } @else if $position == 'bottom' {
    transform: rotate(0deg);
  }
  cursor: pointer;
}

.group-splitter-resizer {
  background: $secondary;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
  position: relative;

  font-weight: 500;
  font-size: 11px;
  color: white;

  &.hidden {
    display: none;
  }
}

.group-splitter-resizer:hover {
  font-size: 15px;
}

.group-splitter-resizer.horizontal {
  height: 11px;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;

  .button-resizer {
    &:before {
      content: '';
      position: absolute;
      height: 11px;
      width: 70px;
      background: $secondary;
      left: calc(50% - 28px);
      top: -5px;
      border-radius: 10px;
      cursor: pointer;
    }

    &:after {
      left: calc(50% + 2px);;
      @include vertical-resizer-icon('left');
    }

  }


  &.folded-vertically {
    .button-resizer {
      &:after {
        @include vertical-resizer-icon('left');
        left:calc(50% + 2px);
        rotate: 180deg;
      }

    }
  }
}

.group-splitter-resizer.horizontal {
  border-top: 5px solid transparent;
  border-bottom: 4px solid transparent;

  .button-resizer:hover {
    &:before {
      height: 14px;
      width: 74px;
      left: calc(50% - 32px);
      top: -6px;
    }


    &:after {
      width: 15px;
      height: 13px;
      left: calc(50% - 3px);
      top: -6px;
    }
  }

  &.folded-vertically {
    .button-resizer:hover {
      &:after {
        left:50%;
      }
    }
  }
  .chevron-resizer-bottom.button-resizer {
    &:after {
      @include vertical-resizer-icon('bottom');
    }
  }

}

.group-splitter-resizer.vertical {
  width: 11px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;

  .button-resizer {
    position: absolute;
    width: 11px;
    height: 70px;
    background: $secondary;
    top: calc(50% - 28px);
    right: 50%;
    transform: translateX(50%);
    border-radius: 10px;
    cursor: pointer;

    &:after {
      @include horizontal-resizer-icon('');
    }
  }
  .chevron-resizer-left.button-resizer {
    &:after {
      @include horizontal-resizer-icon('left');
    }
  }
  .chevron-resizer-right.button-resizer {
    &:after {
      @include horizontal-resizer-icon('right');
    }
  }
  .chevron-resizer-top.button-resizer {
    &:after {
      @include horizontal-resizer-icon('top');
    }
  }
  .chevron-resizer-bottom.button-resizer {
    &:after {
      @include horizontal-resizer-icon('bottom');
    }
  }
  &.folded-horizontally {
    .button-resizer:after {
      rotate: 180deg;
    }
  }
}

.group-splitter-resizer.vertical {
  border-left: 5px solid transparent;
  border-right: 4px solid transparent;

  .button-resizer:hover {
    &:before {
      width: 14px;
      height: 74px;
      top: calc(50% - 32px);
      left: -6px;
    }

    &:after {
      width: 10px;
      height: 15px;
      top: calc(50% - 7px);
      left: 0;
    }
  }
}


.group-splitter-resizer.disabled {
  cursor: not-allowed;
}

.group-splitter-resizer.disabled:hover {
  border-color: transparent;
}
